export default {
  data() {
    return {
      benchmarkwerte: {
        field_1: {
          value: "field_1",
          title: "Grundimmunisierung",
          kosten: "70",
          type: "vorsorge",
        },
        field_2: {
          value: "field_2",
          title: "Chippen 2",
          kosten: "60",
        },
        field_3: {
          value: "field_3",
          title: "Kastration",
          kosten: "0",
        },
        field_4: {
          value: "field_4",
          title: "Tierärztliche Untersuchung",
          kosten: "50",
        },
        field_5: {
          value: "field_5",
          title: "Parasitenvorsorge & Impfung",
          kosten: "100",
        },
        field_6: {
          value: "field_6",
          title: "Blutuntersuchung (Arzthonorar enthalten)",
          kosten: "120",
        },
        field_7: {
          value: "field_7",
          title: "Röntgen (Arzthonorar enthalten)",
          kosten: "120",
        },
        field_8: {
          value: "field_8",
          title: "Ultraschall (Arzthonorar enthalten)",
          kosten: "120",
        },
        field_9: {
          value: "field_9",
          title: "Analdrüsenentzündung",
          kosten: "80",
        },
        field_10: {
          value: "field_10",
          title: "Präputialkatarrh",
          kosten: "120",
        },
        field_11: {
          value: "field_11",
          title: "Allergiker gegen Hausstaubmilben+Futtermilben",
          kosten: "350",
        },
        field_12: {
          value: "field_12",
          title: "Unfall mit Auto (ambulante Behandlung)",
          kosten: "1300",
        },
        field_13: {
          value: "field_13",
          title: "Physiotherapie ( 5 Behandlungen à 45 min zu 60 €)",
          kosten: "300",
        },
        field_14: {
          value: "field_14",
          title: "Verhaltenstherapie",
          kosten: "827",
        },
        field_15: {
          value: "field_15",
          title:
            "Homöopathische Erstanamnese 1,5 h (mit Arzneimittelverordnung)",
          kosten: "150",
        },
        field_16: {
          value: "field_16",
          title: "Akupunktur (Erstuntersuchung + 2 Folgebehandlungen à 60)",
          kosten: "220",
        },
        field_17: {
          value: "field_17",
          title: "Zahnextration",
          kosten: "250",
        },
        field_18: {
          value: "field_18",
          title: "Tumoroperation - Diagnostik",
          kosten: "160",
        },
        field_19: {
          value: "field_19",
          title: "Tumoroperation - Operation",
          kosten: "750",
        },
        field_20: {
          value: "field_20",
          title: "Tumoroperation - Aufenthalt Tierklinik (2 Tage à 50 €)",
          kosten: "100",
        },
      },
      hund: {
        tier_title: {
          value: "tier_title",
          edit: true,
          showintern: true,
          type: "text",
          title: "Tarifname",
          beschreibung: "xxxx",
        }, // ende
        tier_un_id: {
          value: "tier_un_id",
          showvalue: "int",
          showintern: true,
          edit: true,
          type: "int",
          title: "ID Versicherung",
          beschreibung: "ID der Gesellschaft",
        }, // ende
        tier_url: {
          value: "tier_url",
          showvalue: "int",
          showintern: true,
          edit: true,
          type: "int",
          title: "Speaking Link",
          beschreibung: "Speaking Link",
        }, // ende

        tier_antrag: {
          value: "tier_antrag",
          showvalue: "int",
          showintern: true,
          edit: true,
          type: "int",
          title: "Beantragbar",
          beschreibung: "1 = beantragbar",
        }, // ende
        tier_preis_est: {
          value: "tier_preis_est",
          showvalue: "int",
          showintern: true,
          edit: true,
          type: "int",
          title: "Preis geschätzt",
          beschreibung: "1 = Preis geschätzt",
        }, // ende
        tier_wert: {
          value: "tier_wert",
          showvalue: "prozent",
          edit: true,
          type: "int",
          title: "EisbaumWert",
          spezialpop: "rechenweg",
          show_spezial_pop: false,
          popup: "Durchschnittserstattung %",
          beschreibung: "Durchschnittserstattung %",
        }, // ende

        test_value: {
          value: "test_value",
          edit: false,
          type: "text",
          title: "Leistungsniveau Stiftung Wartentest",
          beschreibung: "Bewertung durch Stiftung Wartentest",
        }, // ende
        tier_abrechnung_direkt: {
          value: "tier_abrechnung_direkt",
          edit: true,
          type: "radio",
          title: "Direkte Abrechnung mit Tierarzt möglich",
          beschreibung: "",
          options: {
            option1: {
              label: "ja",
              value: "1",
              beschreibung: "",
            },
            option2: {
              label: "nein",
              value: "0",
              beschreibung: "",
            },
          },
        }, //
        tier_arztwahl: {
          value: "tier_arztwahl",
          edit: true,
          type: "radio",
          title: "Freie Tierarzt- und Klinikwahl",
          beschreibung: "",
          options: {
            option1: {
              label: "ja",
              value: "1",
              beschreibung: "",
            },
            option2: {
              label: "nein",
              value: "0",
              beschreibung: "",
            },
          },
        }, //
        tier_intern_ersattvoraussetzung: {
          value: "tier_intern_ersattvoraussetzung",
          edit: true,
          type: "text",
          title: "Generelle Vorraussetzung für die Erstattung",
          beschreibung: "",
        }, // ende
        tier_annahme_min_alter: {
          value: "tier_annahme_min_alter",
          edit: true,
          showintern: true,
          type: "int",
          title: "Mindest-Annahmealter Tier",
          beschreibung: "",
        }, // ende
        tier_annahme_max_alter: {
          value: "tier_annahme_max_alter",
          edit: true,
          showintern: true,
          type: "int",
          title: "Höchst-Annahmealter Tier",
          beschreibung: "",
        }, // ende
        tier_annahme_kennung: {
          value: "tier_annahme_kennung",
          edit: true,
          type: "radio",
          title: "Annahme nur mit Kennzeichnung?",
          beschreibung: "",
          options: {
            option1: {
              label: "ja",
              value: "1",
              beschreibung: "",
            },
            option2: {
              label: "nein",
              value: "0",
              beschreibung: "",
            },
          },
        }, //
        tier_annahme_ausweis: {
          value: "tier_annahme_ausweis",
          edit: true,
          type: "radio",
          title: "EU-Heimatierausweis verpflichtend?",
          beschreibung: "",
          options: {
            option1: {
              label: "ja",
              value: "1",
              beschreibung: "",
            },
            option2: {
              label: "nein",
              value: "0",
              beschreibung: "",
            },
          },
        }, //
        tier_annahme_impfung: {
          value: "tier_annahme_impfung",
          edit: true,
          type: "radio",
          title: "Grundimmunisierung (nach STIKo Vet) verpflichtend?",
          beschreibung: "",
          options: {
            option1: {
              label: "ja",
              value: "1",
              beschreibung: "",
            },
            option2: {
              label: "nein",
              value: "0",
              beschreibung: "",
            },
          },
        }, //
        tier_got_max: {
          value: "tier_got_max",
          edit: true,
          type: "text",
          title: "Maximaler Abrechnungsfaktor GOT",
          beschreibung: "",
        }, // ende
        tier_vertrag_ausland: {
          value: "tier_vertrag_ausland",
          edit: true,
          type: "text",
          title:
            "Auslandsschutz - Wie lange darf Auslandsaufenthalt sein, dass die Tierarztkosten im Ausland übernommen werden? (Max. Dauer/Europa- oder weltweit) Wenn 0=kein Auslandsschutz)",
          beschreibung: "",
        }, // ende
        tier_begrenzung_anfang: {
          value: "tier_begrenzung_anfang",
          edit: true,
          type: "text",
          title: "Anfängliche Erstattungsbegrenzungen",
          beschreibung: "",
        }, // ende
        tier_leistung_kastration: {
          value: "tier_leistung_kastration",
          edit: true,
          type: "text",
          title: "Kastration / Sterilisation",
          beschreibung: "",
        }, // ende
        tier_leistung_rasseerkrankung: {
          value: "tier_leistung_rasseerkrankung",
          edit: true,
          type: "text",
          title: "Leistung für rassespezifische Erkrankungen",
          beschreibung: "",
        }, // ende
        tier_leistung_huefteuelle: {
          value: "tier_leistung_huefteuelle",
          edit: true,
          type: "text",
          title: "Leistung für Ellenbogen-/Hüftdysplasie",
          beschreibung: "",
        }, // ende
        tier_leistung_kennzeichnung: {
          value: "tier_leistung_kennzeichnung",
          edit: true,
          type: "text",
          title: "Erstattung für Kennzeichnung (Chip / Tätowierung)",
          beschreibung: "",
        }, // ende
        tier_leistung_tarifbesonderheit: {
          value: "tier_leistung_tarifbesonderheit",
          edit: true,
          type: "text",
          title: "Tarifbesonderheiten",
          beschreibung: "",
        }, // ende
        tier_leistung_halterkrankenhaus: {
          value: "tier_leistung_halterkrankenhaus",
          edit: true,
          type: "text",
          title: "Tierversorgung bei Krankenhausaufenthalt Halter",
          beschreibung: "",
        }, // ende

        tier_behandlung_erstattung_zahl: {
          value: "tier_behandlung_erstattung_zahl",
          edit: true,
          type: "text",
          title: "Erstattung Behandlung in %",
          beschreibung: "",
        }, // ende
        tier_behandlung_erstattung_max_jahr: {
          value: "tier_behandlung_erstattung_max_jahr",
          edit: true,
          type: "text",
          title: "Höchstleistung Behandlung pro Jahr in €",
          beschreibung: "",
        }, // ende
        tier_behandlung_erstattung_max_behandlung: {
          value: "tier_behandlung_erstattung_max_behandlung",
          edit: true,
          type: "int",
          feintyle: "nullungebrenzt",
          title: "Höchstleistung pro Behandlung in €",
          beschreibung: "",
        }, // ende
        tier_behandlung_vorsorge: {
          value: "tier_behandlung_vorsorge",
          edit: true,
          type: "text",
          title: "Vorsorgebehandlungen ",
          beschreibung: " Impfungen, Prophylaxe (auch Zahn), Wurmkur, etc.",
        }, // ende
        tier_behandlung_diagnostik: {
          value: "tier_behandlung_diagnostik",
          edit: true,
          type: "text",
          title: "Erstattung für Diagnostik",
          beschreibung: "Blutproben, Röntgen, etc.",
        }, // ende
        tier_behandlung_tierklink: {
          value: "tier_behandlung_tierklink",
          edit: true,
          type: "text",
          title: "Unterbringung in Tierklinik (ohne vorherige OP)",
          beschreibung: "",
        }, // ende
        tier_behandlung_arzneimittel: {
          value: "tier_behandlung_arzneimittel",
          edit: true,
          type: "radio",
          title: "Erstattung für Medikamente und Arzneimittel",
          beschreibung: "",
          options: {
            option1: {
              label: "ja",
              value: "1",
              beschreibung: "",
            },
            option2: {
              label: "nein",
              value: "0",
              beschreibung: "",
            },
          },
        }, //
        tier_behandlung_hilfsmittel: {
          value: "tier_behandlung_hilfsmittel",
          edit: true,
          type: "radio",
          title: "Erstattung für Verband-, Heil- und Hilfsmittel",
          beschreibung: "",
          options: {
            option1: {
              label: "ja",
              value: "1",
              beschreibung: "",
            },
            option2: {
              label: "nein",
              value: "0",
              beschreibung: "",
            },
          },
        }, //
        tier_behandlung_zahnbehandlung: {
          value: "tier_behandlung_zahnbehandlung",
          edit: true,
          type: "text",
          title: "Erstattung für Zahnbehandlung",
          beschreibung: "",
        }, // ende
        tier_behandlung_zahnersatz: {
          value: "tier_behandlung_zahnersatz",
          edit: true,
          type: "text",
          title: "Erstattung für Zahnersatz",
          beschreibung: "",
        }, // ende
        tier_behandlung_geburt: {
          value: "tier_behandlung_geburt",
          edit: true,
          type: "text",
          title: "Natürliche Tier-Geburten",
          beschreibung: "",
        }, // ende
        tier_behandlung_physio: {
          value: "tier_behandlung_physio",
          edit: true,
          type: "text",
          title: "Erstatung für Physiotherapie(ohne vorheriger OP)",
          beschreibung: "",
        }, // ende
        tier_behandlung_homopathie: {
          value: "tier_behandlung_homopathie",
          edit: true,
          type: "text",
          title: "Erstattung für Homöopathie (ohne vorheriger OP)",
          beschreibung: "",
        }, // ende
        tier_behandlung_akupunktur: {
          value: "tier_behandlung_akupunktur",
          edit: true,
          type: "text",
          title: "Erstattung für Akupunktur (ohne vorheriger OP)",
          beschreibung: "",
        }, // ende
        tier_behandlung_verhaltenstherapie: {
          value: "tier_behandlung_verhaltenstherapie",
          edit: true,
          type: "text",
          title: "Erstattung für Verhaltenstherapie (ohne vorheriger OP)",
          beschreibung: "",
        }, // ende
        tier_behandlung_fehlerangeboren: {
          value: "tier_behandlung_fehlerangeboren",
          edit: true,
          type: "text",
          title: "Erstattung für angeborene Fehlentwicklungen",
          beschreibung: "",
        }, // ende
        tier_behandlung_grobefahr: {
          value: "tier_behandlung_grobefahr",
          edit: true,
          type: "text",
          title: "Erstattung für grobe Fahrlässigkeit",
          beschreibung: "",
        }, // ende
        tier_behandlung_toetung: {
          value: "tier_behandlung_toetung",
          edit: true,
          type: "text",
          title: "Erstattung für Einschläferung",
          beschreibung: "",
        }, // ende
        tier_behandlung_leistung_zusätzlich: {
          value: "tier_behandlung_leistung_zusätzlich",
          edit: true,
          type: "text",
          title: "Zusätzliche Leistungen Behandlung",
          beschreibung: "",
        }, // ende
        tier_op_erstattung_zahl: {
          value: "tier_op_erstattung_zahl",
          edit: true,
          type: "int",
          feintyle: "nullungebrenzt",
          title: "Erstattung Operation in %",
          beschreibung: "",
        }, // ende

        tier_op_erstattung_max_jahr: {
          value: "tier_op_erstattung_max_jahr",
          edit: true,
          type: "text",
          title: "Höchstleistung Operation pro Jahr in € ",
          beschreibung: "",
        }, // ende
        tier_op_erstattung_max_op: {
          value: "tier_op_erstattung_max_op",
          edit: true,
          type: "int",
          feintyle: "nullungebrenzt",
          title: "Höchstleistung Operation pro OP in €",
          beschreibung: "",
        }, // ende
        tier_op_diagnostik: {
          value: "tier_op_diagnostik",
          edit: true,
          type: "radio",
          title: "Erstattungen für Diagnostik und Untersuchungen vor einer OP",
          beschreibung: "",
          options: {
            option1: {
              label: "ja",
              value: "1",
              beschreibung: "",
            },
            option2: {
              label: "nein",
              value: "0",
              beschreibung: "",
            },
          },
        }, //
        tier_op_tierklink: {
          value: "tier_op_tierklink",
          edit: true,
          type: "radio",
          title: "Unterbringung in Tierklinik (nach einer OP)",
          beschreibung: "",
          options: {
            option1: {
              label: "ja",
              value: "1",
              beschreibung: "",
            },
            option2: {
              label: "nein",
              value: "0",
              beschreibung: "",
            },
          },
        }, //
        tier_op_tierklinik_text: {
          value: "tier_op_tierklinik_text",
          edit: true,
          type: "text",
          title: "Unterbringung in Tierklinik (nach OP) Text",
          beschreibung: "",
        }, // ende
        tier_op_arzneimittel: {
          value: "tier_op_arzneimittel",
          edit: true,
          type: "radio",
          title: "Erstattungen für Behandlungen und Medikamente nach einer OP",
          beschreibung: "",
          options: {
            option1: {
              label: "ja",
              value: "1",
              beschreibung: "",
            },
            option2: {
              label: "nein",
              value: "0",
              beschreibung: "",
            },
          },
        }, //
        tier_op_physio: {
          value: "tier_op_physio",
          edit: true,
          type: "text",
          title: "Erstattung Physiotherapie nach OP ",
          beschreibung: "",
        }, // ende
        tier_op_wurzelextraktion: {
          value: "tier_op_wurzelextraktion",
          edit: true,
          type: "text",
          title: "Erstattung für Wurzelbehandlung und Zahnextraktion",
          beschreibung: "",
        }, // ende

        tier_op_zahnstein: {
          value: "tier_op_zahnstein",
          edit: true,
          type: "text",
          title: "Erstattung für Zahnsteinentfernung",
          beschreibung: "",
        }, // ende
        tier_op_narkose: {
          value: "tier_op_narkose",
          edit: true,
          type: "text",
          title: "Erstatung für Operationen unter Narkose",
          beschreibung: "",
        }, // ende
        tier_op_homopathie: {
          value: "tier_op_homopathie",
          edit: true,
          type: "text",
          title: "Erstattung für Homöopathie nach einer OP",
          beschreibung: "",
        }, // ende
        tier_op_akupunktur: {
          value: "tier_op_akupunktur",
          edit: true,
          type: "text",
          title: "Erstattung für Akupunktur nach einer OP",
          beschreibung: "",
        }, // ende
        tier_op_laser: {
          value: "tier_op_laser",
          edit: true,
          type: "text",
          title: "Erstattung für Lasertherapie nach OP",
          beschreibung: "",
        }, // ende
        tier_op_prothesen: {
          value: "tier_op_prothesen",
          edit: true,
          type: "text",
          title:
            "Erstattung für Prothesen (inkl. Operationen und Behandlungen)",
          beschreibung: "",
        }, // ende
        tier_op_leistung_zusätzlich: {
          value: "tier_op_leistung_zusaetzlich",
          edit: true,
          type: "text",
          title: "Zusätzliche Leistungen Operationen",
          beschreibung: "",
        }, // ende
        tier_wartezeit_janein: {
          value: "tier_wartezeit_janein",
          edit: true,
          type: "radio",
          title: "Hat der Tarif eine Wartezeit",
          beschreibung: "",
          options: {
            option1: {
              label: "ja",
              value: "1",
              beschreibung: "",
            },
            option2: {
              label: "nein",
              value: "0",
              beschreibung: "",
            },
          },
        }, //
        tier_wartezeit: {
          value: "tier_wartezeit",
          edit: true,
          type: "text",
          title: "Wartezeit Text",
          beschreibung: "",
        }, // ende
        tier_wartezeit_besonders: {
          value: "tier_wartezeit_besonders",
          edit: true,
          type: "text",
          title: "Zusätzliche Wartezeiten",
          beschreibung: "",
        }, // ende
        tier_vertrag_mindestvertragszeit: {
          value: "tier_vertrag_mindestvertragszeit",
          edit: true,
          type: "text",
          title: "Mindestvertragslaufzeit",
          beschreibung: "",
        }, // ende
        tier_vertrag_kuendigung: {
          value: "tier_vertrag_kuendigung",
          edit: true,
          type: "text",
          title: "Kündigungsfrist",
          beschreibung: "",
        }, // ende
        tier_intern_zahlweise: {
          value: "tier_intern_zahlweise",
          edit: true,
          type: "text",
          title: "Zahlungsturnus",
          beschreibung: "",
        }, // ende
        tier_inter_doppelversicherung_moeg: {
          value: "tier_inter_doppelversicherung_moeg",
          edit: true,
          showintern: true,
          type: "radio",
          title: "Doppelversicherung möglich",
          beschreibung: "",
          options: {
            option1: {
              label: "ja",
              value: "1",
              beschreibung: "",
            },
            option2: {
              label: "nein",
              value: "0",
              beschreibung: "",
            },
          },
        }, //
        tier_intern_gesundheitsfragen_janein: {
          value: "tier_intern_gesundheitsfragen_janein",
          edit: true,
          showintern: true,
          type: "radio",
          title: "Hat der Tarif Gesundheitsfragen",
          beschreibung: "",
          options: {
            option1: {
              label: "ja",
              value: "1",
              beschreibung: "",
            },
            option2: {
              label: "nein",
              value: "0",
              beschreibung: "",
            },
          },
        }, //
        tier_intern_gesundheitsfragen: {
          value: "tier_intern_gesundheitsfragen",
          edit: true,
          showintern: true,
          type: "text",
          title: "Gesundheitsfragen Text",
          beschreibung: "",
        }, // ende
      },
    };
  },
  methods: {
    showFieldComputedPop(field) {
      // this.$log.debug("showFieldComputedPop", field);
      let computedField = this.mixinFieldFilter(field, "hund");
      return computedField.popup;
    },
    showFieldComputedNr(field, table, nr) {
      if (table == "hund") {
        var computedField = this.mixinFieldFilter(field, "hund");
      }
      if (table == "meta") {
        var computedField = this.mixinFieldFilter(field, "meta");
      }
      //    this.$log.debug('mixin2', this.tarif2)
      // this.$log.debug('this.computedField', computedField)
      if (computedField)
        if (nr == 1) {
          return this.mixinFieldValue(
            computedField,
            this.tarif1[computedField.value]
          );
        }
      if (nr == 2 && this.tarif2.tier_id > 0) {
        this.$log.debug("mixin2 now", this.tarif2[computedField.value]);
        return this.mixinFieldValue(
          computedField,
          this.tarif2[computedField.value]
        );
      }

      if (nr == 3 && this.tarif3.tier_id > 0) {
        return this.mixinFieldValue(
          computedField,
          this.tarif3[computedField.value]
        );
      }
    },
    showFieldComputedDirect(field, table, tarif) {
      if (table == "hund") {
        let computedField = this.mixinFieldFilter(field, "hund");
        if (computedField) {
          return this.mixinFieldValue(computedField, tarif);
        }
      }
      if (table == "zahn") {
        let computedField = this.mixinFieldFilter(field, "zahn");

        this.$log.debug("computedField 2", computedField);
        if (computedField) {
          return this.mixinFieldValue(computedField, tarif);
        }
      }
      if (table == "annahme") {
        let computedField = this.mixinFieldFilter(field, "annahme");
        if (computedField) {
          return this.mixinFieldValue(computedField, tarif);
        }
      }
      if (table == "meta") {
        let computedField = this.mixinFieldFilter(field, "meta");
        if (computedField) {
          return this.mixinFieldValue(computedField, tarif);
        }
      }
    },
    showFieldComputedFilled(field, table) {
      let erg;
      erg = false;
      let valueField = "";
      if (table == "hund") {
        let computedField = this.mixinFieldFilter(field, "hund");
        if (computedField) {
          valueField = this.mixinFieldValue(
            computedField,
            this.tarif[computedField.value]
          );
        }

        if (valueField) {
          erg = true;
        }
      }

      return erg;
    },

    showFieldComputed(field, table) {
      if (table == "hund") {
        let computedField = this.mixinFieldFilter(field, "hund");
        if (computedField) {
          return this.mixinFieldValue(
            computedField,
            this.tarif[computedField.value]
          );
        }
      }
      if (table == "meta") {
        let computedField = this.mixinFieldFilter(field, "meta");
        if (computedField) {
          return this.mixinFieldValue(
            computedField,
            this.tarif[computedField.value]
          );
        }
      }
    },
    mixinFieldHeader(field, table) {
      this.$log.debug("Header", field);
      if (table == "hund" && this.hund[field]) {
        return this.hund[field].beschreibung;
      }
      if (table == "meta" && this.meta[field]) {
        this.$log.debug("header", this.meta[field]);
        return this.meta[field]["text"];
      }
    },
    mixinFieldFilter(field, table) {
      if (table == "hund") {
        //  this.$log.debug('khfield', field)
        return this.hund[field];
      }
    },
    mixinWertFilter(field, table, was) {
      if (table == "benchmark") {
        this.$log.debug("benchmark", field);
        if (was == "title") {
          return this.benchmarkwerte[field].title;
        }
        if (was == "kosten") {
          return this.benchmarkwerte[field].kosten;
        }
      }
    },

    mixinWertFilterCompare(field, value) {
      if (this.benchmarkwerte[field].kosten != value) {
        return value + "€";
      } else {
        return '<span class="green--text"> ' + value + " €</span>";
      }
    },

    mixinFieldValue(field, value) {
      // this.$log.debug('valueradio', field.type)
      // this.$log.debug('value only', value)
      if (field.type == "int") {
        if (
          field.feintyle &&
          field.feintyle == "nullungebrenzt" &&
          value == 0
        ) {
          return "unbegrenzt";
        } else {
          if (field.showvalue && field.showvalue == "prozent") {
            return value + "%";
          } else {
            return value;
          }
        }
      }
      if (field.type == "text" || field.type == "textarea") {
        if (field.showvalue && field.showvalue == "prozent") {
          return value + "%";
        } else {
          return value;
        }
      }
      if (field.type == "radio") {
        for (const item in field.options) {
          // console.log('item', field.options[item].value);
          if (field.options[item].value == value) {
            return field.options[item].label;
          }
        }
      } //end radio
    }, // end mixinfieldValue
  }, // end methods
};
