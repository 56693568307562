<template>
  <div style="max-width: 1200px">
    <h2>WaizmannWert Admin {{ tarif.tier_title }}</h2>
    <!-- <a class="my-2" @click="showEingabe = !showEingabe"
      >neue Zeile hinzufügen</a -->

    <div>
      {{ insert }}
      <v-row>
        <v-col cols="12" md="3">Year</v-col>
        <v-col cols="12" md="6"
          ><v-text-field v-model="insert.bench_year"
        /></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">Behandlung</v-col>
        <v-col cols="12" md="6">
          <!-- <div v-for="item in benchmarkwerte" :key="item.bench_field_id">
            {{ item.title }}
          </div> -->

          <v-radio-group v-model="insert.bench_field">
            <v-radio
              :label="item.title"
              :value="item.value"
              v-for="item in benchmarkwerte"
              :key="item.value"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3">Value</v-col>
        <v-col cols="12" md="6"
          ><v-text-field v-model="insert.bench_value"
        /></v-col>
      </v-row>

      <v-row v-if="insert.bench_field != '' && insert.bench_year != ''">
        <v-col cols="12" md="3">Value</v-col>
        <v-col cols="12" md="6">
          <v-btn @click="addNeuItem()">speichern & nächste Eingabe</v-btn>
        </v-col>
      </v-row>
    </div>

    <v-row class="my-4">
      <v-col cols="12" md="2"><b>Behandlung</b></v-col>
      <v-col cols="12" md="2"><b>Jahr</b></v-col>
      <v-col cols="12" md="2"><b>Kosten</b></v-col>
      <v-col cols="12" md="2"><b>Erstattung</b></v-col>
    </v-row>

    <v-row class="my-4" v-for="item in benchmark" :key="item.bench_id">
      <v-col cols="12" md="2">{{
        mixinWertFilter(item.bench_field, "benchmark", "title")
      }}</v-col>
      <v-col cols="12" md="2">{{ item.bench_year }}</v-col>
      <v-col cols="12" md="2">{{
        mixinWertFilter(item.bench_field, "benchmark", "kosten")
      }}</v-col>
      <v-col cols="12" md="2">{{ item.bench_value }}</v-col>
    </v-row>
  </div>
</template>

<script>
import fieldMixin from "./../mixins/fieldMixin";
export default {
  name: "index-wert-admin",
  mixins: [fieldMixin],
  components: {},

  data: function () {
    return {
      showEingabe: false,
      tarif: {
        tier_title: "",
      },
      select: {
        type: "einzel",
        id: this.$route.params.id,
        number: "one",
      },
      insert: {
        bench_tarif_id: this.$route.params.id,
        bench_value: 0,
        bench_year: "",
        bench_field: "",
      },
      update: {
        antrag_id: 0,
        antrag_wtb_id: this.$route.params.id,
      },
      benchmark: false,
      showInsertSuccess: false,
    };
  },
  created() {
    this.getTarife();
  },

  methods: {
    getTarife() {
      this.$store
        .dispatch("getTarife", this.select)
        .then((resp) => {
          if (resp.data.tarife) {
            this.tarif = resp.data.tarife;
            this.getTarifBenchmark();
          }
        })
        .catch((err) => {
          this.$log.error(err);
        });
    },
    getTarifBenchmark() {
      this.$store
        .dispatch("getTarifBenchmark", this.select)
        .then((resp) => {
          if (resp.data.benchmark) {
            this.benchmark = resp.data.benchmark;
          }
        })
        .catch((err) => {
          this.$log.error(err);
        });
    },
    addNeuItem() {
      this.$store.commit("start_loading_full");
      this.$store.dispatch("insertNeuerBenchmark", this.insert).then((resp) => {
        this.insert.bench_value = 0;
        this.insert.bench_field = "";

        this.$store.commit("stop_loading_full");
        this.getTarifBenchmark(this.tarif.tarif_id);
      });
    },
    getFragen(tarif_id) {
      this.$store.dispatch("getTarif", tarif_id).then((resp) => {
        this.fragen = resp.data.fragen;
      });
    },

    updateFrage(item) {
      this.$store.commit("start_loading_full");
      this.update = item;
      this.$store.dispatch("updateAntragFrage", this.update).then((resp) => {
        this.$store.commit("stop_loading_full");
        this.getTarifAntragFragen(this.tarif.tier_id);
        this.update.antrag_id = 0;
      });
    },
  },
};
</script>
